import { BehaviorSubject } from 'rxjs'
import { CleaningRequest } from '../../domain/entities/CleaningRequest'
import { OpenCleaningRequestRepository } from '../../domain/interfaces/OpenCleaningRequestRepository'
import { GetCleaningRequestAttachments } from '../../domain/usecases/GetCleaningRequestAttachments'

export type RequestDetailsState = {
  status: "loading" | "loaded" | "closing" | "unable-to-close" | "closed"
  request: CleaningRequest | undefined
}

export class CleaningRequestDetailsViewModel {

  private _state = new BehaviorSubject<RequestDetailsState>({
    status: "loading",
    request: undefined,
  });

  constructor(
    private readonly openCleaningRequestRepository: OpenCleaningRequestRepository,
    private readonly getCleaningRequestAttachments: GetCleaningRequestAttachments
  ) { }

  readonly state = this._state.asObservable();

  currentState(): RequestDetailsState {
    return this._state.getValue()
  }

  loadRequest(id: string) {

    const currentState = this._state.value
    this._state.next({ ...currentState, status: "loading" })

    this.openCleaningRequestRepository
      .getCleaningRequest(id)
      .then((result) => {
        if (result?.source === 'MoTo ERP') {
          this.getCleaningRequestAttachments.execute(id).then((attachments) =>
            this._state.next({
              status: "loaded",
              request: { ...result, images: attachments.map((it) => it.link) }
            })
          ).catch((_error) =>
            this._state.next({
              status: "loaded",
              request: result
            }))
        } else {
          this._state.next({
            status: "loaded",
            request: result
          })
        }
      })
      .catch((_error) =>
        this._state.next({
          status: "loaded",
          request: undefined,
        })
      )
  }

  closeRequest(): void {
    const currentState = this._state.value
    const id = currentState.request?.id

    if (id === undefined) {
      this._state.next({ ...currentState, status: "unable-to-close" })
    } else {
      this._state.next({ ...currentState, status: "closing" })
      this.openCleaningRequestRepository.closeCleaningRequest(id)
        .then((success) => {
          if (success) {
            this._state.next({ ...currentState, status: "closed" })
          } else {
            this._state.next({ ...currentState, status: "unable-to-close" })
          }
        })
        .catch((_error) => this._state.next({ ...currentState, status: "unable-to-close" }))
    }
  }
}