import { DomainContext } from "../../domain/DomainContext"
import React, { useEffect, useState, useContext } from "react"
import {
  ClosedRequestsViewModel,
  ClosedRequestsState,
} from "./ClosedRequestsViewModel"
import { ClosedRequestsTable } from "./ClosedRequestsTable"
import { ExportButton } from "./ExportButton"
import { Box, LinearProgress } from "@mui/material"
import { PeriodOption, Timeselector } from './TimeSelector'

export function useViewModel(): [ClosedRequestsState, ClosedRequestsViewModel] {
  const { getClosedRequestsViewModel } = useContext(DomainContext)
  const [viewModel] = useState(() => getClosedRequestsViewModel())
  const [state, setState] = useState<ClosedRequestsState>(
    viewModel.currentState()
  )

  useEffect(() => {
    const subscription = viewModel.state.subscribe(setState)

    return function cleanup() {
      subscription.unsubscribe()
    }
  }, [viewModel])

  return [state, viewModel]
}

export function ClosedRequestsPage() {
  const [state, viewModel] = useViewModel()

  const loadingBar = () => {
    if (state.type === "loading") {
      return <LinearProgress />
    }
  }

  return (
    <>
      {loadingBar()}
      <Box m={2} pb={1}>
        <Timeselector
          selectTimePeriod={(period: PeriodOption) =>
            viewModel.selectTimePeriod(period)
          }
        />
        <ExportButton requests={state.requests} />
      </Box>
      <ClosedRequestsTable
        requests={state.requests}
      />
    </>
  )
}
