import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination,
  TableSortLabel,
} from "@mui/material"
import React from "react"
import moment from "moment"
import { CleaningRequest } from "../../domain/entities/CleaningRequest"
import { getComparator, Order, stableSort } from '../common-components/table-sorting'

export type RequestRow = {
  id: string
  vehicle: string
  category: string
  description: string
  location: string
  createdAt: number
  images: string[]
  cleaningTypeId: string
  closedAt: number
  workLocation: string
  comment: string,
  closedByGroup: string
}

type Props = {
  requests: CleaningRequest[]
}

export function ClosedRequestsTable(props: Props) {
  const [page, setPage] = React.useState(0)
  const [order, setOrder] = React.useState<Order>("desc")
  const [orderBy, setOrderBy] = React.useState<keyof RequestRow>("closedAt")

  const rowsPerPage = 200

  const rows: RequestRow[] = props.requests.map((it) => {
    let workLocation = it.workLocation ?? "-"
    if (workLocation === "OTHER") {
      workLocation = "Muu sijainti"
    }

    let closedByGroup = ''
    switch (it.closedByGroup) {
      case 'cleaner': {
        closedByGroup = 'Siivooja'
        break
      }
      case 'supervisor': {
        closedByGroup = 'Työnjohto'
        break
      }
      case 'vr-admin': {
        closedByGroup = 'Admin'
        break
      }
      case 'cleaner-admin': {
        closedByGroup = 'Admin'
        break
      }

      default: closedByGroup = it.closedByGroup ?? '-'
    }
    return { ...it, closedAt: it.closedAt ?? 0, workLocation: workLocation, comment: it.comment ?? "-", closedByGroup: closedByGroup }
  })

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof RequestRow
  ) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const createSortHandler =
    (property: keyof RequestRow) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property)
    }

  return (
    <Box m={2} pb={4}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <SortableCell
                title="Runko"
                selectedOrderBy={orderBy}
                orderBy="vehicle"
                order={order}
                sortHandler={createSortHandler("vehicle")}
              />
              <SortableCell
                title="Luotu"
                selectedOrderBy={orderBy}
                orderBy="createdAt"
                order={order}
                sortHandler={createSortHandler("createdAt")}
              />
              <SortableCell
                title="Suljettu"
                selectedOrderBy={orderBy}
                orderBy="closedAt"
                order={order}
                sortHandler={createSortHandler("closedAt")}
              />
              <SortableCell
                title="ID"
                selectedOrderBy={orderBy}
                orderBy="id"
                order={order}
                sortHandler={createSortHandler("id")}
              />
              <SortableCell
                title="Siivoustyyppi"
                selectedOrderBy={orderBy}
                orderBy="cleaningTypeId"
                order={order}
                sortHandler={createSortHandler("cleaningTypeId")}
              />
              <SortableCell
                title="Kategoria"
                selectedOrderBy={orderBy}
                orderBy="category"
                order={order}
                sortHandler={createSortHandler("category")}
              />
              <TableCell align="left">Kuvaus</TableCell>
              <SortableCell
                title="Sijainti"
                selectedOrderBy={orderBy}
                orderBy="location"
                order={order}
                sortHandler={createSortHandler("location")}
              />
              <SortableCell
                title="Paikkakunta"
                selectedOrderBy={orderBy}
                orderBy="workLocation"
                order={order}
                sortHandler={createSortHandler("workLocation")}
              />
              <SortableCell
                title="Ryhmä"
                selectedOrderBy={orderBy}
                orderBy="closedByGroup"
                order={order}
                sortHandler={createSortHandler("closedByGroup")}
              />
              <TableCell align="left">Kommentti</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const time = moment(row.createdAt).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
                let closedAtTime = moment(row.closedAt).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
                if (row.closedAt === 0) {
                  closedAtTime = "-"
                }

                return (
                  <TableRow hover key={index}>
                    <TableCell align="left">{row.vehicle}</TableCell>
                    <TableCell align="left">{time}</TableCell>
                    <TableCell align="left">{closedAtTime}</TableCell>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.cleaningTypeId}</TableCell>
                    <TableCell align="left">{row.category}</TableCell>
                    <TableCell align="left" style={{ maxWidth: 500 }}>
                      {row.description}
                    </TableCell>
                    <TableCell align="left">{row.location}</TableCell>
                    <TableCell align="left">{row.workLocation}</TableCell>
                    <TableCell align="left">{row.closedByGroup}</TableCell>
                    <TableCell align="left" style={{ maxWidth: 400 }}>
                      {row.comment}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[200]}
        component="div"
        count={props.requests.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={() => { }}
      />
    </Box>
  )
}


function SortableCell(props: {
  title: string
  selectedOrderBy: keyof RequestRow
  orderBy: keyof RequestRow
  order: Order
  sortHandler: (event: React.MouseEvent<unknown>) => void
}) {
  return (
    <TableCell
      sortDirection={
        props.selectedOrderBy === props.orderBy ? props.order : false
      }
      align="left"
    >
      <TableSortLabel
        active={props.selectedOrderBy === props.orderBy}
        direction={
          props.selectedOrderBy === props.orderBy ? props.order : "asc"
        }
        onClick={props.sortHandler}
      >
        {props.title}
      </TableSortLabel>
    </TableCell>
  )
}