import { DomainContext } from "../../domain/DomainContext"
import React, { useEffect, useState, useContext } from "react"
import {
  CleaningRequestsViewModel,
  RequestsState,
} from "./CleaningRequestViewModel"
import { RequestsTable } from "./RequestsTable"
import { ExportButton } from "./ExportButton"
import { Box, LinearProgress } from "@mui/material"

export function useViewModel(): [RequestsState, CleaningRequestsViewModel] {
  const { getCleaningRequestsViewModel } = useContext(DomainContext)
  const [viewModel] = useState(() => getCleaningRequestsViewModel())
  const [state, setState] = useState<RequestsState>(
    viewModel.currentState()
  )

  useEffect(() => {
    const subscription = viewModel.state.subscribe(setState)

    return function cleanup() {
      subscription.unsubscribe()
    }
  }, [viewModel])

  return [state, viewModel]
}

export function CleaningRequestsPage() {
  const [state] = useViewModel()

  const loadingBar = () => {
    if (state.status === "loading") {
      return <LinearProgress />
    }
  }

  return (
    <>
      {loadingBar()}
      <Box m={2} pb={4}>
        <ExportButton requests={state.requests} />
      </Box>
      <RequestsTable
        requests={state.requests}
      />
    </>
  )
}
