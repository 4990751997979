import React, { useEffect, useState, useContext } from "react"
import { Box, Button, Grid, LinearProgress, Link, Paper, Typography } from "@mui/material"
import { useParams } from 'react-router-dom'
import { DomainContext } from '../../domain/DomainContext'
import { CleaningRequestDetailsViewModel, RequestDetailsState } from './CleaningRequestDetailsViewModel'
import moment from "moment"
import { CloseRequestDialog } from './CloseRequestDialog'
import { useHistory } from 'react-router-dom'
import { API, Auth } from 'aws-amplify'
import { RequestImageDialog } from '../requests/RequestImageDialog'

type Params = {
  id: string
}

export function useViewModel(id: string): [RequestDetailsState, CleaningRequestDetailsViewModel] {
  const { getCleaningRequestDetailsViewModel } = useContext(DomainContext)
  const [viewModel] = useState(() => getCleaningRequestDetailsViewModel())
  const [state, setState] = useState<RequestDetailsState>(
    viewModel.currentState()
  )

  useEffect(() => {
    viewModel.loadRequest(id)
    const subscription = viewModel.state.subscribe(setState)

    return function cleanup() {
      subscription.unsubscribe()
    }
  }, [viewModel, id])

  return [state, viewModel]
}

export const CleaningRequestDetailsPage = () => {
  const { id } = useParams<Params>()
  const [state, viewModel] = useViewModel(id)
  const history = useHistory()

  const [image, setImage] = React.useState<string | undefined>(undefined)

  const closeImageDialog = () => setImage(undefined)

  const loadImage = async (id: string) => {
    // TODO: now id param can either be id (vikaosio) or url (moto). This is confusing. Refactor.
    if (id.includes("http")) {
      setImage(id)
    } else {
      // TODO: hacky solution to pass a special string here. Refactor this.
      setImage("loading")
      const session = await Auth.currentSession()

      await API.get("client-api", "/cleaning-requests/images/" + id, {
        headers: {
          Authorization: session.getIdToken().getJwtToken(),
          accept: "image/jpeg",
          "Cache-Control": "private, max-age=31536000",
        },
        responseType: "blob",
      })
        .then((response) => setImage(URL.createObjectURL(response)))
        .catch((error) => setImage(undefined))
    }
  }

  if (state.status === 'closed') {
    history.replace("/requests")
  }

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false)
  const closeDialog = () => setConfirmDialogOpen(false)

  const loadingBar = () => {
    if (state.status === "loading" || state.status === "closing") {
      return <LinearProgress />
    }
  }

  const closeRequest = () => {
    viewModel.closeRequest()
  }

  const errorMessage = () => {
    if (state.status === "unable-to-close") {

      return (
        <Box m={0} pt={3}>
          <Paper elevation={0} style={{ background: '#E85430', textAlign: 'center' }}>
            <Typography variant="body1" color="white">
              Siivouspyynnön päättäminen epäonnistui. Ole hyvä ja yritä uudelleen.
            </Typography>
          </Paper>
        </Box>
      )
    }
  }

  const content = () => {
    if (state.request !== undefined) {
      return (
        <>
          <Paper elevation={0} style={{ background: '#F1F1F1' }}>
            <Box m={2} pb={1}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Typography variant="h6">Tunnus</Typography>
                  <Typography variant="body2">{state.request.id}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">Runko</Typography>
                  <Typography variant="body2">{state.request.vehicle}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">Sijainti</Typography>
                  <Typography variant="body2">{state.request.location}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">Luotu</Typography>
                  <Typography variant="body2">{moment(state.request.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">Allokoitu</Typography>
                  <Typography variant="body2">{state.request.allocated ? "Kyllä" : "Ei"}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <Paper elevation={0} style={{ background: '#FFFFFF' }}>
            <Box m={1} pb={1}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Typography variant="h6">Kuvaus</Typography>
                  <Typography variant="body2">{state.request.description}</Typography>
                </Grid>
              </Grid>


              <Box m={0} pt={3}>
                <Typography variant="h6">Kuvaliitteet</Typography>
                {state.request.images.map((image, index) => (
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      loadImage(image)
                    }}
                    sx={{ marginRight: "4px" }}
                  >
                    {"Kuva " + (index + 1)}
                  </Link>
                ))
                }
              </Box>

              <Box m={0} pt={3}>
                <Button
                  disabled={state.status === 'closing'}
                  variant="contained"
                  color="info"
                  onClick={() => setConfirmDialogOpen(true)}
                >
                  Päätä siivouspyyntö
                </Button>
              </Box>
              {errorMessage()}
            </Box>
          </Paper>
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <>
      {loadingBar()}
      <CloseRequestDialog open={confirmDialogOpen} onClose={closeDialog} onConfirm={() => { closeRequest(); closeDialog() }} />
      <Box m={2} pb={4}>
        <RequestImageDialog image={image} onClose={closeImageDialog} />
        {content()}
      </Box>
    </>
  )
}