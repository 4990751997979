import React from 'react'
import { BackendImpl } from '../network/BackendImpl'
import { CleaningRequestDetailsViewModel } from '../ui/cleaning-request-details/CleaningRequestDetailsViewModel'
import { CleaningsViewModel } from '../ui/cleanings/CleaningsViewModel'
import { ClosedRequestsViewModel } from '../ui/closed-requests/ClosedRequestsViewModel'
import { InspectionsViewModel } from '../ui/inspections/InspectionsViewModel'
import { CleaningRequestsViewModel } from '../ui/requests/CleaningRequestViewModel'
import { UsersViewModel } from '../ui/users/UsersViewModel'
import { Backend } from './interfaces/Backend'
import { InMemoryOpenCleaningRequestRepository, OpenCleaningRequestRepository } from './interfaces/OpenCleaningRequestRepository'
import { CreateUserImpl } from './usecases/CreateUser'
import { DeleteUserImpl } from './usecases/DeleteUser'
import { ListCleaningsImpl } from './usecases/ListCleanings'
import { ListClosedCleaningRequestsImpl } from './usecases/ListClosedCleaningRequests'
import { ListInspectionsImpl } from './usecases/ListInspections'
import { ListUsersImpl } from './usecases/ListUsers'
import { UpdateUserImpl } from './usecases/UpdateUser'
import { GetCleaningRequestAttachmentsImpl } from './usecases/GetCleaningRequestAttachments'

interface Domain {
    setUserHasAuthenticated: (value: boolean) => void
    isUserAuthenticated: () => boolean,

    getUsersViewModel: () => UsersViewModel
    getCleaningsViewModel: () => CleaningsViewModel
    getCleaningRequestsViewModel: () => CleaningRequestsViewModel
    getClosedRequestsViewModel: () => ClosedRequestsViewModel
    getInspectionsViewModel: () => InspectionsViewModel,
    getCleaningRequestDetailsViewModel: () => CleaningRequestDetailsViewModel
}

let authenticated: boolean = false

const backend: Backend = new BackendImpl()
const openCleaningRequestRepository: OpenCleaningRequestRepository = new InMemoryOpenCleaningRequestRepository(backend)

export const domain: Domain = {
    setUserHasAuthenticated: (value: boolean) => {
        authenticated = value
    },
    isUserAuthenticated: () => authenticated,
    getUsersViewModel: () => new UsersViewModel(
        new ListUsersImpl(backend),
        new UpdateUserImpl(backend),
        new DeleteUserImpl(backend),
        new CreateUserImpl(backend)
    ),
    getCleaningsViewModel: () => new CleaningsViewModel(
        new ListCleaningsImpl(backend)
    ),
    getCleaningRequestsViewModel: () => new CleaningRequestsViewModel(
        openCleaningRequestRepository
    ),
    getInspectionsViewModel: () => new InspectionsViewModel(
        new ListInspectionsImpl(backend)
    ),
    getClosedRequestsViewModel: () => new ClosedRequestsViewModel(
        new ListClosedCleaningRequestsImpl(backend)
    ),
    getCleaningRequestDetailsViewModel: () => new CleaningRequestDetailsViewModel(
        openCleaningRequestRepository,
        new GetCleaningRequestAttachmentsImpl(backend)
    )
}

export const DomainContext = React.createContext<Domain>(domain)