import { AttachmentDTO, Backend } from '../interfaces/Backend'

export interface GetCleaningRequestAttachments {
    execute(requestId: string): Promise<AttachmentDTO[]>
}

export class GetCleaningRequestAttachmentsImpl implements GetCleaningRequestAttachments {
    constructor(
        private readonly backend: Backend
    ) { }

    async execute(requestId: string): Promise<AttachmentDTO[]> {
        const result = await this.backend.getAttachments(requestId)

        if (result.success) {
            return result.data.filter((it) => it.mimetype.startsWith("image"))
        } else {
            throw Error("Getting attachments failed")
        }
    }
}